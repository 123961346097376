@import "core/style/settings";

#default-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 1200px;
  margin: 0 auto;

  .main-container {
    display: flex;
    flex-grow: 1;
    height: 100%;

    .informations-screen-container {
      width: 50%;
    }

    .header {
      padding-right: @spacing-md;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      padding-top: @spacing-lg;
      padding-bottom: @spacing-lg;
      max-width: 600px;
      margin: 0 auto;
      flex-grow: 1;
      
      &.fixed {
        position: fixed;
        height: 100%;
      }

      .content-container {
        display: flex;
        flex-direction: column;

        &.has-information-screen {
          height: 100%;
          justify-content: center;
        }

        .information-container {
          display: flex;
          justify-content: space-between;
          padding: @spacing-sm;
          margin-top: 32px;
          margin-right: @spacing-md;
          margin-left: @spacing-md;
          border-radius: 6px;
          background-color: @information-screen-background;

          .logo-container {
            max-width: 130px;
            max-height: 130px;
            width: 100%;
            height: auto;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: @spacing-md;
          }

          .sum-container {
            display: flex;
            justify-content: flex-end;

            .sum-label {
              text-align: right;
              font-size: var(--font-size-sm);
            }

            .sum {
              font-size: var(--font-size-xxl);
              font-weight: 700;
              color: @text-color-black;
            }
          }
        }
      }

      .security-icon {
        display: flex;
        justify-content: flex-end;
        margin-right: @spacing-md;
        margin-left: @spacing-md;
      }

      .footer {
        display: flex;
        justify-content: space-between;
        margin-top: @spacing-md;
        padding-right: @spacing-md;
        padding-left: @spacing-md;
        align-items: flex-end;

        .footer-content {
          display: flex;
          align-items: center;
          color: @text-color-black;
          font-size: var(--font-size-xs);
          font-weight: 400;

          .seperator {
            width: 1px;
            height: 18px;
            background-color: @text-color-black;
            padding-top: @spacing-xs;
            padding-bottom: @spacing-xs;
            margin-left: @spacing-xs;
            margin-right: @spacing-xs;
          }

          .powered-by svg {
            margin-left: @spacing-xs;
          }

          .links {
            margin-right: @spacing-xs;
            cursor: pointer;
          }
        }

        .security-icon {
          display: flex;
          width: 65px;
          height: 65px;
          justify-content: flex-end;
          margin-right: 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  #default-layout {
    .main-container {
      flex-direction: column;

      .informations-screen-container {
        width: 100%;
      }

      .header {
        margin-top: @spacing-md;
        margin-bottom: @spacing-md;
        margin-left: @spacing-md;
        margin-right: @spacing-md;
        padding-right: 0px;
        padding-left: 0px;
      }

      .content {
        padding-left: @spacing-md;
        padding-right: @spacing-md;

        .content-container {
          .information-container {
            margin-top: 0px;
            margin-left: 0px;
            margin-right: 0px;
          }
        }

        .security-icon {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          padding-right: 0px;
          padding-left: 0px;
        }

        .footer {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  #default-layout {
    .main-container {
      .content {

        .footer {
          display: flex;
          flex-direction: column;
          align-items: center;

          .footer-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 0px;

            .seperator {
              display: none;
            }

            .links {
              margin-top: @spacing-xs;
              margin-right: 0.25rem;
              margin-left: 0.25rem;

              cursor: pointer;
            }
          }

          .security-icon {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: @spacing-sm;
            margin-right: 0px;
            margin-left: 0px;
          }
        }
      }
    }
  }
}
