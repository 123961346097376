@import "core/style/style.less";

#payment-screen {
  width: 100%;
  padding-top: @spacing-lg;
  padding-left: @spacing-md;
  padding-right: @spacing-md;

  &.--has-information-screen {
    padding-top: 0px;
  }

  .title {
    font-weight: 500;
    color: @text-color-black;
    font-size: var(--font-size-lg);
    margin-bottom: @spacing-md;
  }

  .text {
    font-weight: 400;
    color: @text-color-black;
    font-size: var(--font-size-md);
    margin-bottom: @spacing-lg;
  }

  .payment-methods-container {
    display: flex;
    flex-direction: column;
    padding-top: @spacing-lg;

    .payment-method {
      margin-bottom: @spacing-md;
      width: 100%;
    }

    .seperator-container {
      margin-bottom: @spacing-md;
    }

    .paypal {
      background-color: @color-yellow;
      border: none;
    }

    .apple-pay {
      font-size: var(--font-size-sm);
      font-weight: 400;

      .icon-right {
        margin-left: 0px;
      }
    }

    .google-pay {
      font-size: var(--font-size-sm);
      font-weight: 400;

      .icon-right {
        margin-left: @spacing-xs;
        height: 20px;
        max-width: 50px;

        svg {
          height: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  #payment-screen {
    padding-top: @spacing-lg;
    padding-right: 0px;
    padding-left: 0px;

    &.--has-information-screen {
      padding-top: 0px;
    }
  }
}
