@import "core/style/style";

#header-container {
  display: flex;
  justify-content: space-between;
  padding-left: @spacing-md;

  .return-button {
    font-size: var(--font-size-sm);
    font-weight: 600;
    padding-right: @spacing-md;
    padding-left: 0px;
    background-color: transparent;
    color: @text-color-black;
    box-shadow: none;
    border: none;

    .icon-left {
      margin-right: @spacing-sm;
    }
  }
}
