@import "core/style/style.less";

#seperator-component {
  display: flex;
  align-items: center;

  .seperator-line {
    height: 2px;
    width: 100%;
    background-color: @color-light-grey;
  }

  .seperator-text {
    font-size: var(--font-size-sm);
   color: @text-color-black;
    font-weight: 400;
    margin-left: @spacing-md;
    margin-right: @spacing-md;
  }
}
