@import "core/style/settings";

#information-screen {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: @spacing-md;
  background-color: @information-screen-background;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    flex-grow: 1;
    padding: @spacing-lg;

    .container-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: @spacing-xl;

      .store-info {
        display: flex;
        width: 100%;
        align-items: center;

        .logo-container {
          max-width: 130px;
          max-height: 130px;
          width: 100%;
          height: auto;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: @spacing-md;

          .logo {
            max-width: 100%;
            max-height: 100%;
            height: auto;
            width: auto;
          }
        }
      }

      .title {
        color: @text-color-black;
        font-size: var(--font-size-xl);
        font-weight: 400;
      }
    }

    .dropdown {
      display: flex;
      flex-shrink: 0;
      cursor: pointer;

      .text {
        margin-right: @spacing-xs;
        color: @text-color-black;
        font-size: var(--font-size-sm);
      }

      .dropdown-icon {
        &.is-expanded {
          transform: rotate(180deg);
        }
      }
    }

    .information-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
      transition: height 0.25s ease-in-out;

      .box {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 20px;
        border-radius: 10px;
        background-color: #f3f3f3;
        color: @text-color-black;
        margin-bottom: @spacing-sm;

        &.is-not-expanded {
          display: none;
          top: 0px;
          background-color: #f3f3f3;
          z-index: 3;
          color: @text-color-black;
          transition: background-color 0.25s ease-in-out;
          overflow: hidden;

          .title {
            color: @text-color-black;
          }

          .description {
            color: @text-color-black;
          }

          &:nth-child(-n + 3) {
            display: block; /* Show only the first three boxes */
          }

          &:nth-child(2) {
            position: absolute;
            z-index: 2;
            width: 90%;
            left: 50%;
            transform: translateX(-50%);
            background-color: #e6e6e6;
            top: -16px;
            transition: background-color 0.35s ease-in-out;
          }

          &:nth-child(3) {
            position: absolute;
            z-index: 1;
            width: 80%;
            left: 50%;
            transform: translateX(-50%);
            background-color: #dfdfdf;
            top: -32px;
            transition: background-color 0.45s ease-in-out;
          }
        }

        .box-content {
          display: flex;
          width: 100%;
          height: 100%;

          .image {
            width: 160px;
            height: 120px;
            object-fit: cover;
            border-radius: 8px;
            margin-right: @spacing-sm;
          }

          .content-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            justify-content: space-between;

            .box-header {
              display: flex;
              justify-content: space-between;
              width: 100%;
            }
          }

          .date {
            display: flex;
            align-items: center;
            font-size: var(--font-size-md);
            margin-top: @spacing-xs;

            .date-icon {
              margin-right: @spacing-xs;

              &.black {
                path {
                  stroke: @color-black;
                }
              }
            }
          }
        }

        .title {
          font-size: var(--font-size-lg);
          font-weight: 400;
          margin-right: @spacing-sm;
        }

        .description-container {
          display: flex;
          margin-top: @spacing-sm;
          margin-bottom: @spacing-sm;

          .description {
            margin-top: 0px;
          }
        }

        .description {
          font-size: var(--font-size-md);
          font-weight: 400;
          color: @text-color-black;
          margin-top: @spacing-xs;
        }

        .price {
          font-size: var(--font-size-lg);
          font-weight: 400;
          white-space: nowrap;
        }
      }

      .seperator {
        width: 100%;
        height: 2px;
        background-color: @color-light-grey;
        margin-top: @spacing-md;
        margin-bottom: @spacing-lg;
      }

      .add-button {
        font-size: var(--font-size-sm);
        font-weight: 400;
        background-color: #0a0a0a26;
        border: none;
        margin-bottom: @spacing-lg;
        transition: background-color 0.2s ease;

        &:hover {
          background-color: @color-black;
        }
      }

      .sum-container {
        display: flex;
        width: 100%;
        justify-content: flex-end;

        .sum-label {
          text-align: right;
          font-size: var(--font-size-sm);
        }

        .sum {
          font-size: var(--font-size-xxl);
          font-weight: 700;
          color: @text-color-black;
        }
      }
    }

    .footer {
      display: flex;
      align-items: center;
      color: @text-color-black;
      font-size: var(--font-size-xs);
      font-weight: 400;
      margin-top: @spacing-md;

      .seperator {
        width: 1px;
        height: 100%;
        background-color: @text-color-black;
        padding-top: @spacing-xs;
        padding-bottom: @spacing-xs;
        margin-left: @spacing-xs;
        margin-right: @spacing-xs;
      }

      .powered-by svg {
        margin-left: @spacing-xs;
      }

      .links {
        margin-right: @spacing-xs;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  #information-screen {
    .container {
      padding: @spacing-lg @spacing-md;

      .footer {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  #information-screen {
    .container {
      .container-header {
        .store-info {
          .logo-container {
            max-width: 80px;
            max-height: 80px;
          }
        }
      }

      .information-content {
        .box {
          .box-content {
            .image {
              width: 140px;
              height: 100px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  #information-screen {
    .container {
      .information-content {
        .box {
          .box-content {
            .image {
              width: 100px;
              height: 100px;
            }
          }
        }
      }
    }
  }
}
