@import "core/style/settings";

#language-select-component {
  position: relative;

  .select-box {
    display: flex;
    align-items: center;
    background-color: transparent;
    color: @text-color-black;
    height: 45px;
    border: 1px solid @color-brown;
    border-radius: 8px;
    font-size: var(--font-size-sm);
    font-weight: 600;
    cursor: pointer;

    .selected-value {
      display: flex;
      align-items: center;
      margin-left: @spacing-sm;
    }

    .flag-icon {
      margin-left: @spacing-sm;
      height: 20px;
    }

    .dropdown-icon {
      margin-left: @spacing-md;
      margin-right: @spacing-sm;
    }

    &.open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .options-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border-top: none;
    background-color: @color-white;
    color: @text-color-black;
    z-index: 1000;
    overflow-y: auto;

    .option-item {
      display: flex;
      align-items: center;
      height: 45px;
      cursor: pointer;
      font-size: var(--font-size-sm);
      font-weight: 600;
      border-bottom: 1px solid @color-brown;
      border-right: 1px solid @color-brown;
      border-left: 1px solid @color-brown;

      .value {
        display: flex;
        align-items: center;
        margin-left: @spacing-sm;
      }

      .flag-icon {
        margin-left: @spacing-sm;
        height: 20px;
      }

      &:hover {
        background-color: @color-black;
        color: @color-white;
      }
    }
  }
}
