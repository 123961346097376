// Colors
@color-black: #0a0a0a;
@text-color-black: #3C3D3D;
@color-white: #ffffff;
@color-near-white: #919191;
@color-light-grey: #d9d9d9;
@color-brown: #847d7d;
@color-grey: #e6ebf1;
@color-yellow: #ffd140;
@color-red: #F1484C;
@color-green: #11C350;
@information-screen-background: #F8F8F8;

// Spacings
@spacing-none: 0; // 0
@spacing-half-unit: 0.25rem; // 4px
@spacing-xs: 0.5rem; // 8px
@spacing-sm: 1rem; // 16px
@spacing-md: 1.5rem; // 24px
@spacing-lg: 2rem; // 32px
@spacing-xl: 3rem; // 32px

// Font sizes
// They are defined as css variables, so they can be updated automatically on screen size change
:root {
  --font-size-xs: 10px;
  --font-size-sm: 13px;
  --font-size-md: 15px;
  --font-size-lg: 20px;
  --font-size-xl: 28px;
  --font-size-xxl: 40px;
}

@media screen and (max-width: 1100px) {
  :root {
    --font-size-xs: 10px;
    --font-size-sm: 12px;
    --font-size-md: 14px;
    --font-size-lg: 18px;
    --font-size-xl: 22px;
    --font-size-xxl: 24px;
  }
}
