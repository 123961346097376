@import "core/style/settings";

#invalid-link-screen {
  display: flex;
  min-height: 100%;
  justify-content: center;
  align-items: center;

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 750px;
    margin-right: @spacing-md;
    margin-left: @spacing-sm;

    .title {
      margin-top: @spacing-xs;
      font-size: var(--font-size-xl);
      font-weight: 500;
     color: @text-color-black;
      text-align: center;
    }

    .description {
      margin-top: @spacing-md;
      font-size: var(--font-size-md);
      font-weight: 400;
      color: @color-near-white;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 800px) {
  #invalid-link-screen {
    .content {
      .title {
        margin-top: 0px;
      }

      .description {
        margin-top: @spacing-sm;
      }
    }
  }
}
