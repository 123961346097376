@import "core/style/settings.less";

#button-component {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 15px;
  font-weight: 500;
  font-size: var(--font-size-md);
  height: 45px;
  cursor: pointer;

  &.is-expandable {
    transition: width 0.2s ease-in-out;
    padding: 10px;
    min-width: 50px;
    width: 50px;

    .icon-left {
      margin-right: 0px;
    }

    .icon-right {
      margin-left: 0px;
    }

    .button-text {
      opacity: 0;
      transition: opacity 0.1s ease-in-out;
    }
  }

  &.primary {
    border: 1px solid @color-brown;
    background-color: @color-black;
    color: @color-white;
  }

  &.secondary {
    border: 1px solid @color-grey;
    background-color: @color-grey;
   color: @text-color-black;
  }

  &.full-width {
    width: 100%;
  }

  &.box-shadow {
    box-shadow: 0px 0px 15px 1px #ffffff66 inset,
      4px 12px 26px 0px rgba(0, 0, 0, 0.3);
  }

  .icon-left {
    margin-right: @spacing-sm;
  }

  .icon-right {
    margin-left: @spacing-sm;
  }

  &.is-expanded {
    width: 100%;
    padding: 15px;

    .button-text {
      opacity: 1;
      transition-delay: 0.1s; /* Slight delay to ensure the button expands first */
    }
  }
}
