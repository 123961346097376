.dn {
  display: none;
}

.db {
  display: block;
}

// Flex box classes
.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.flex-grow-1 {
  flex-grow: 1;
}

.gap-sm {
  gap: @spacing-sm;
}

.gap-md {
  gap: @spacing-md;
}
