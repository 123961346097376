@import "core/style/settings";

#select-component {
  &.full-width {
    width: 100%;
  }

  .label {
    font-size: var(--font-size-md);
    font-weight: 500;
    margin-bottom: @spacing-xs;
  }

  .optional-info {
    font-size: var(--font-size-xs);
    font-weight: 500;
    vertical-align: sub;
    margin-bottom: @spacing-half-unit;
    margin-left: @spacing-half-unit;
  }

  .select-container {
    position: relative;

    .select-box {
      height: 45px;
      padding-left: @spacing-sm;
      font-size: var(--font-size-md);
      font-weight: 400;
      border: 1px solid @color-grey;
      border-radius: 8px;
      outline: none;

      .select-input-field {
        height: 45px;
        background: transparent;
        outline: none;
        border: none;
      }

      .selected-value {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: space-between;
        padding-right: @spacing-sm;
      }

      &.open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        border-color: #d9dadc;
      }

      .error-icon {
        position: absolute;
        right: 14px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
      }
    }

    .options-list {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      border-top: none;
      background-color: @color-white;
     color: @text-color-black;
      z-index: 1000;
      overflow-y: auto;

      .option-item {
        display: flex;
        align-items: center;
        height: 45px;
        cursor: pointer;
        font-size: var(--font-size-sm);
        font-weight: 400;
        border-bottom: 1px solid @color-grey;
        border-right: 1px solid @color-grey;
        border-left: 1px solid @color-grey;

        .value {
          display: flex;
          align-items: center;
          margin-left: @spacing-sm;
        }
      }
    }
  }

  .error-message {
    display: flex;
    align-items: center;
    height: 15px;
    font-size: var(--font-size-xs);
    color: @color-red;
  }
}
