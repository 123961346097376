@import "core/style/settings";

#bank-information {
  width: 100%;
  height: 100%;
  padding-top: @spacing-md;
  margin-top: @spacing-xl;
  margin-left: @spacing-md;
  margin-right: @spacing-md;

  .title {
    text-align: left;
    margin-top: @spacing-xs;
    font-size: var(--font-size-xl);
    font-weight: 500;
    color: @text-color-black;
    margin-bottom: @spacing-xs;
  }

  .description {
    font-weight: 400;
    font-size: var(--font-size-md);
    color: @text-color-black;
    margin-bottom: @spacing-xl;
  }

  .bank-details {
    display: flex;
    flex-direction: column;

    .item {
      display: flex;
      width: 100%;
      padding: @spacing-sm;
      border: 1px solid @color-grey;

      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom: none;
      }

      &:nth-child(3) {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top: none;
        padding: 0;
      }

      .label {
        font-size: var(--font-size-md);
        font-weight: 500;
        color: @text-color-black;
        margin-right: @spacing-md;
      }

      .value {
        font-size: var(--font-size-md);
        font-weight: 400;
        color: @text-color-black;
      }

      .sub-item {
        width: 100%;
        display: flex;
        padding: @spacing-sm;

        &:first-child {
          border-right: 1px solid @color-grey;
        }

        &:last-child {
          border-right: none;
        }
      }

      &.has-sub-items {
        display: flex;
      }

      &.reference-number {
        display: flex;
        justify-content: space-between;
        margin-top: @spacing-md;
        border-radius: 8px;

        .copy-icon {
          cursor: pointer;
        }

        &.copied {
          border-color: @color-green;
          transition: border-color 0.2s ease;

          .label {
            color: @color-green;
            transition: color 0.2s ease;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  #bank-information {
    padding-top: 0px;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
}
