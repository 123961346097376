@import "core/style/settings.less";

#input-component {
  &.full-width {
    width: 100%;

    .input {
      width: 100%;
    }
  }

  .input-wrapper {
    position: relative;

    .input {
      height: 45px;
      padding-left: @spacing-sm;
      font-size: var(--font-size-md);
      font-weight: 400;
      border: 1px solid @color-grey;
      color: @text-color-black;
      border-radius: 8px;
      outline: none;

      &:focus {
        border: 1px solid #d9dadc;
      }

      &::placeholder {
        color: @color-near-white;
      }
    }

    .error-icon {
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }

    .success-icon {
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none; // Prevent the icon from interfering with input
    }
  }

  .label {
    font-size: var(--font-size-md);
    font-weight: 500;
    color: @text-color-black;
    margin-bottom: @spacing-xs;
  }

  .optional-info {
    font-size: var(--font-size-xs);
    font-weight: 500;
    vertical-align: sub;
    margin-bottom: @spacing-half-unit;
    margin-left: @spacing-half-unit;
  }

  .error-message {
    display: flex;
    align-items: center;
    height: 15px;
    font-size: var(--font-size-xs);
    color: @color-red;
  }
}
