#root {
  height: 100%;
  font-family: "Open sans", sans-serif;
  overflow-x: hidden;
}

// Global variables (colors, spacings, etc.)
@import "./settings";

// Useful global classes
@import "./layout/display";
