@import "core/style/settings";

#privacy-screen {
  display: flex;
  justify-content: center;

  .privacy-container {
    display: flex;
    flex-direction: column;
    width: 750px;
    margin-top: @spacing-lg;

    .title {
      text-align: left;
      margin-top: 0px;
      font-size: var(--font-size-lg);
      font-weight: 600;
      color: @text-color-black;
    }

    .text {
      text-align: left;
      margin-top: @spacing-sm;
      font-size: var(--font-size-md);
      color: @text-color-black;
    }
  }
}
