@import "core/style/settings";

#request-bank-data-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: @spacing-lg;
  margin-left: @spacing-md;
  margin-right: @spacing-md;
  width: 100%;
  height: 100%;

  .form-container {
    margin-top: @spacing-xl;
    width: 100%;

    .form-input-container {
      display: flex;
      gap: @spacing-sm;
      margin-top: @spacing-sm;

      &.personal-details {
        margin-top: @spacing-sm;
        margin-bottom: @spacing-sm;
      }
    }
  }

  .pay-now-button {
    margin-top: @spacing-md;
  }
}

@media only screen and (max-width: 1100px) {
  #request-bank-data-screen {
    padding-top: 0px;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media only screen and (max-width: 750px) {
  #request-bank-data-screen {
    .form-container {
      .form-input-container {
        flex-direction: column;
        gap: @spacing-sm;
      }
    }
  }
}
