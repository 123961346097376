@import "core/style/settings";

#authorized-payment-screen {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;

  .header {
    margin-top: @spacing-lg;
    width: 100%;
    max-width: 750px;
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 750px;

    .title {
      margin-top: @spacing-xs;
      font-size: var(--font-size-xl);
      font-weight: 500;
     color: @text-color-black;
      text-align: center;
    }

    .description {
      margin-top: @spacing-md;
      font-size: var(--font-size-md);
      font-weight: 400;
      color: @color-near-white;
      text-align: center;
    }

    .box {
      margin-top: @spacing-lg;
      margin-bottom: @spacing-md;
      width: 100%;
      background-color: #f8f8f8;
      border: 1px solid @color-grey;
      border-radius: 8px;
      padding: 40px;
      padding-bottom: 30px;

      .header-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: @spacing-md;

        .header {
          width: 100%;
          display: flex;

          .box-content {
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: space-between;

            .box-header {
              display: flex;
              justify-content: space-between;
            }

            .date {
              display: flex;
              align-items: center;
              font-size: var(--font-size-md);

              .date-icon {
                margin-right: @spacing-xs;
                path {
                  stroke: black;
                }
              }
            }
          }

          .image {
            width: 160px;
            height: 120px;
            object-fit: cover;
            border-radius: 8px;
            margin-right: @spacing-sm;
          }

          .title {
            text-align: left;
            margin-top: 0px;
            font-size: var(--font-size-lg);
            font-weight: 400;
           color: @text-color-black;
          }

          .price {
            margin-top: 0px;
            font-size: var(--font-size-lg);
            font-weight: 400;
           color: @text-color-black;
            white-space: nowrap;
            margin-left: @spacing-md;
          }

          .description-container {
            display: flex;
            margin-top: @spacing-sm;
            margin-bottom: @spacing-sm;

            .description {
              margin-top: 0px;
            }
          }

          .description {
            text-align: left;
            margin-top: @spacing-xs;
            margin-bottom: @spacing-xs;
            font-size: var(--font-size-sm);
            font-weight: 500;
            color: @color-near-white;
          }
        }
      }

      .seperator {
        width: 100%;
        height: 1px;
        background-color: @color-grey;
      }

      .content {
        display: flex;
        flex-direction: column;
        margin: 0px;
        margin-top: @spacing-lg;
        width: 100%;

        .item {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: @spacing-md;

          .label {
            font-size: var(--font-size-sm);
            font-weight: 400;
            color: @color-near-white;
          }

          .value {
            font-size: var(--font-size-sm);
            font-weight: 400;
           color: @text-color-black;
          }

          &:last-child {
            margin-bottom: @spacing-lg;
          }
        }
      }

      .footer {
        margin-top: @spacing-md;

        .item {
          display: flex;
          width: 100%;
          justify-content: space-between;

          .label {
            font-size: var(--font-size-lg);
            font-weight: 400;
           color: @text-color-black;
          }

          .value {
            font-size: var(--font-size-lg);
            font-weight: 400;
           color: @text-color-black;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  #authorized-payment-screen {
    height: auto;

    .header {
      margin-right: 0px;
    }

    .content {
      width: 100%;
      margin-top: @spacing-md;
      margin-left: @spacing-md;
      margin-right: @spacing-md;
      margin-bottom: @spacing-md;

      .title {
        margin-top: 0px;
      }

      .description {
        margin-top: @spacing-sm;
      }

      .box {
        padding: 20px;
        padding-bottom: 20px;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  #authorized-payment-screen {
    .content {
      .box {
        .header-container {
          .header {
            .image {
              width: 100px;
              height: 100px;
            }
          }
        }
      }
    }
  }
}
