@import "core/style/settings.less";

#checkbox-component {
  display: flex;
  flex-direction: column;

  .checkbox-container {
    position: relative;

    .checkbox {
      display: block;
      height: 18px;
      width: 18px;
      border-radius: 3px;
      border: 1px solid @color-grey;
      margin-right: @spacing-sm;
      cursor: pointer;

      -webkit-appearance: none; /* Remove default styling in WebKit browsers */
      -moz-appearance: none; /* Remove default styling in Firefox */
      appearance: none; /* Remove default styling in modern browsers */

      &:checked {
        transition: background-color 0.2s ease;
        background-color: @color-black; /* Background color when checked */
      }
    }

    .check-icon-container {
      position: absolute;
      pointer-events: none;
      display: flex;
      height: 18px !important;
      width: 18px !important;
      top: 0;
      left: 0;
      justify-content: center;
      align-items: center;

      .check-icon {
        height: 15px;
        width: 15px;
      }
    }
  }

  .label {
    font-size: var(--font-size-sm);
    font-weight: 400;
    color: @text-color-black;
    cursor: pointer;

    a {
      color: @text-color-black;
      font-weight: 600;
      text-decoration: none;
    }
  }

  .error-message {
    display: flex;
    align-items: center;
    height: 15px;
    font-size: var(--font-size-xs);
    color: @color-red;
  }
}
