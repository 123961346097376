// Styles for the adyen checkout plugin
@import "core/style/settings";

// All fields required information
.adyen-checkout-form-instruction {
  display: none;
}

// Loading spinner styles
.adyen-checkout__spinner.adyen-checkout__spinner--large {
  height: 40px;
  width: 40px;
  border-top-color: transparent;
}

// Pay Button
.adyen-checkout__button.adyen-checkout__button--pay {
  width: 100%;
  background: @color-black;
  border: 1px solid @color-brown;
  box-shadow: 0px 0px 15px 1px #ffffff66 inset,
    4px 12px 26px 0px rgba(0, 0, 0, 0.3);
  border-radius: 8px;

  &:disabled {
    background-color: #6e6e6e;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 100%;
  }

  .adyen-checkout__spinner {
    height: 20px;
    width: 20px;
    border-top-color: transparent;
  }
}
.adyen-checkout__button__text {
  font-size: var(--font-size-md);
  font-weight: 600;
  color: @color-white;
}

// Labels
.adyen-checkout__label {
  &__text {
    font-size: var(--font-size-md);
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: @text-color-black;
  }

  &--focused {
    .adyen-checkout__label__text {
      color: @text-color-black;
    }
  }
}

.adyen-checkout__label--focused + .adyen-checkout__input-wrapper {
  border-color: #d9dadc;
  box-shadow: none;
}

// Input fields
.adyen-checkout__input-wrapper {
  height: 45px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid @color-grey;
  border-radius: 8px;
  box-shadow: none;

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    color: #bbbec5;
  }

  &:focus-within {
    border: 1px solid #d9dadc;
    box-shadow: none;

    &:hover {
      border: 1px solid #d9dadc;
      box-shadow: none;
    }
  }

  &:focus {
    border: 1px solid #d9dadc;
    box-shadow: none;

    &:hover {
      border: 1px solid #d9dadc;
      box-shadow: none;
    }
  }

  &:active {
    border: 1px solid #d9dadc;
    box-shadow: none;

    &:hover {
      border: 1px solid #d9dadc;
      box-shadow: none;
    }
  }

  &--focus {
    border: 1px solid #d9dadc;
    box-shadow: none;
  }

  &:hover {
    border: 1px solid @color-grey;
    box-shadow: none;
  }
}

// Checkbox label
.adyen-checkout__store-details {
  border: none;
  padding: 0;
}

.adyen-checkout__checkbox__label {
  font-size: var(--font-size-md);
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: @text-color-black;
}

.adyen-checkout__checkbox__label:after {
  border: 1px solid @color-grey !important;
  box-shadow: none !important;
  margin-top: 2px;
  background-color: red;
}

// Adjust checkbox hover styles
.adyen-checkout__checkbox__input:hover {
  + .adyen-checkout__checkbox__label:after {
    border: 1px solid @color-grey !important;
    box-shadow: none !important;
  }
}

.adyen-checkout__checkbox__input {
  // Checkbox
  + .adyen-checkout__checkbox__label:after {
    height: 18px;
    width: 18px;
  }

  // Checkmark icon
  + .adyen-checkout__checkbox__label:before {
    top: 5px;
    left: 2px;
  }
}

.adyen-checkout__checkbox__input:checked
  + .adyen-checkout__checkbox__label:after {
  transition: background-color 0.2s ease;
  background-color: @color-black; /* Background color when checked */
}

// Error messages
.adyen-checkout-contextual-text--error {
  margin-top: 2px;
  font-size: var(--font-size-xs);
  color: @color-red;
}

// Info messages
.adyen-checkout-contextual-text {
  margin-top: 2px;
  font-size: var(--font-size-xs);
  color: @text-color-black;
}

.adyen-checkout__icon {
  vertical-align: inherit;
}

// Supported cards images
.adyen-checkout__card__brands {
  justify-content: flex-end;
  margin-bottom: 0px;

  .adyen-checkout__card__brands__brand-wrapper {
    img {
      position: absolute;
    }
  }
}

// Override styles to make expiration date full width when CVC is hidden
.adyen-checkout__field__exp-date {
  width: 100%;
  margin-right: 0!important;
}

.adyen-checkout__field__cvc {
  width: 100%;
  margin-left: 16px!important;
}
